
$(document).on('click', '.btn-contact', function(){
    $(".masthead").find(".contact-form-container").toggle();
    $(".masthead").find(".join_button").toggle();
});
$(document).on('click', '.maybelatter_form', function(){
    $(".masthead").find(".contact-form-container").toggle();
    $(".masthead").find(".join_button").toggle();
});
$(document).on('click', '.share', function(){
    event.preventDefault();
    $(".masthead").find(".contact-form-container").show();
    $(".masthead").find(".join_button").toggle();
});
$(document).on('click', '.offers_button', function(){
    event.preventDefault();
    // console.log("click click");
    $(".showcase").find(".showcase-offers").toggle();
});
$(document).on('click', '.best_price', function(){
  event.preventDefault();
  // console.log("click click");
  $(".showcase").find(".showcase-offers").toggle();
});
$(document).on('click', '.short-description', function(){
  event.preventDefault();
  // console.log("click click");
  $(".showcase").find(".short-description").toggle();
  $(".showcase").find(".showcase-description").toggle();

});
$(document).on('click', '.showcase-description', function(){
  event.preventDefault();
  // console.log("click click");
  $(".showcase").find(".short-description").toggle();
  $(".showcase").find(".showcase-description").toggle();

});



$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
  $('[data-toggle="popover"]').popover();
});
